import { FC } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"
import { BaseLink } from "src/components/common/base-link"
import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"

import { create } from "src/helpers/bem"
import { NavigationDesktopItem } from "src/hooks/navigation/useDesktopNavigationItems"

import styles from "./NavigationDesktopItemContent.module.scss"

const bem = create(styles, "NavigationDesktopItemContent")

type NavigationDesktopItemContentProps = {
  item: NavigationDesktopItem
  onNavigationContentLeave: () => void
}
export const NavigationDesktopItemContent: FC<
  NavigationDesktopItemContentProps
> = ({ item, onNavigationContentLeave }) => {
  const { trackNavigationSubItemClick, trackNavigationLinkClick } =
    useNavigationTrackingEvents()

  const handleMainSubNavigationItemClick = (value?: string) => {
    onNavigationContentLeave()
    trackNavigationSubItemClick(`${item?.text}-${value}`, "desktop")
  }

  const handleNavigationLinkClick = (value?: string) => {
    onNavigationContentLeave()
    trackNavigationLinkClick(`${item?.text}-${value}`, "desktop")
  }

  return (
    <div className={bem("flyout-content")}>
      {item?.content?.map((subItem, subItemIndex) => (
        <div key={subItemIndex}>
          <div className={bem("flyout-content-title")}>
            {subItem?.icon && (
              <Icon
                className={bem("icon")}
                aria-hidden="true"
                name={subItem?.icon}
              />
            )}
            {!!subItem?.link ? (
              <Button
                variant="tertiary"
                onClick={() => handleMainSubNavigationItemClick(subItem.text)}
                href={subItem?.link}
              >
                {subItem.text}
              </Button>
            ) : (
              subItem?.text
            )}
          </div>
          <ul className={bem("flyout-content-nav")}>
            {subItem?.content?.map((navigationLinks, index) => (
              <li key={index} className={bem("flyout-content-nav-item")}>
                <BaseLink
                  className={bem("flyout-content-nav-item-link")}
                  onClick={() =>
                    handleNavigationLinkClick(navigationLinks.text)
                  }
                  href={navigationLinks?.href ?? ""}
                >
                  {navigationLinks?.icon && (
                    <Icon
                      className={bem("icon")}
                      aria-hidden="true"
                      name={navigationLinks?.icon}
                    />
                  )}
                  {navigationLinks.text}
                </BaseLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
