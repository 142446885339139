import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { signInWithOAuth } from "src/helpers/next-auth/client"
import { useTranslation } from "src/hooks/translation"
import { mergeLoginPopoverStatus } from "src/states/loginPopoverStatus"

import styles from "./LoginPopoverContent.module.scss"

const bem = create(styles, "LoginPopoverContent")

export type LoginPopoverContentProps = {
  onClose: () => void
}

export const LoginPopoverContent: FC<LoginPopoverContentProps> = ({
  onClose,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.accountIcon.popover

  const onLoginClick = async () => {
    // TODO: Add tracking
    mergeLoginPopoverStatus({ interacted: true })
    await signInWithOAuth()
  }

  const onPopoverClose = () => {
    // TODO: Add tracking
    mergeLoginPopoverStatus({ interacted: true })
    onClose()
  }

  return (
    <div className={bem("container")}>
      <div className={bem("header")}>
        <p className={bem("title")}>{translations.title}</p>
        <button className={bem("close")} onClick={onPopoverClose}>
          <Icon name="close" />
        </button>
      </div>
      <div className={bem("content")}>
        <p className={bem("text")}>{translations.description}</p>
        <Button
          variant="tertiary"
          className={bem("button")}
          onClick={onLoginClick}
        >
          {translations.button}
        </Button>
      </div>
    </div>
  )
}
