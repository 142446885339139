import { ContentfulButtonProps } from "src/components/cck/elements/contentfulButton"
import { ROUTES } from "src/config"
import { useTranslation } from "src/hooks/translation"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

export type NavigationMobileItemContent = {
  text?: string
  items?: {
    text?: string
    href?: string
  }[]
  content?: {
    text?: string
    href?: string
  }[]
}

export type NavigationMobileItem = {
  id?: string
  text?: string
  href?: string
  mainNavigationItem?: ContentfulButtonProps
  /**
   To show selected item content
   */
  activeHrefs?: string[]
  content?: NavigationMobileItemContent[]
}

export const useMobileNavigationItems = (
  isAuthenticated?: boolean,
  headerNavigation?: HeaderNavigationProps[] | null,
) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.navigation.mobile.items

  // This needs to be hardcoded as it is account related navigation
  const accountNavigationItems: NavigationMobileItem[] = isAuthenticated
    ? [
        {
          text: translations.account.title,
          activeHrefs: [ROUTES.account.home],
          content: [
            {
              items: [
                {
                  text: translations.account.subNavigation.home,
                  href: ROUTES.account.home,
                },
                {
                  text: translations.account.subNavigation.bookings,
                  href: ROUTES.account.bookings,
                },
                {
                  text: translations.account.subNavigation.costOverview,
                  href: ROUTES.account.costOverview,
                },
                {
                  text: translations.account.subNavigation.vehicles,
                  href: ROUTES.account.myVehicles.index,
                },
                {
                  text: translations.account.subNavigation.myData,
                  href: ROUTES.account.myAccount.index,
                },
              ],
            },
            {
              text: translations.account.help,
              items: [
                {
                  text: translations.account.subNavigation.qa,
                  href: ROUTES.qa,
                },
                {
                  text: translations.account.subNavigation.support,
                  href: ROUTES.contact,
                },
              ],
            },
          ],
        },
      ]
    : ([] as NavigationMobileItem[])

  const headerNavigationItems: NavigationMobileItem[] =
    !!headerNavigation?.length
      ? headerNavigation?.map((navigationItem) => {
          return {
            text: navigationItem.title ?? "",
            mainNavigationItem: navigationItem.mainNavigationItem,
            activeHrefs: navigationItem?.navigationItems?.flatMap(
              (navigationItem) =>
                navigationItem.navigationItems
                  .map((item) => item.link)
                  .filter((link): link is string => !!link),
            ),
            content: navigationItem?.navigationItems?.map((navigationItem) => {
              return {
                text: navigationItem?.name,
                content: navigationItem.navigationItems.map((item) => {
                  return {
                    text: item?.name,
                    href: item.link,
                  }
                }),
              }
            }),
          }
        })
      : []

  const navigationItems: NavigationMobileItem[] = [
    ...accountNavigationItems,
    ...headerNavigationItems,
  ]

  return navigationItems
}
