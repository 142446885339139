import router from "next/router"

import { ROUTES } from "src/config"
import { signInWithOAuth } from "src/helpers/next-auth/client"
import { AuthData } from "src/helpers/next-auth/types"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const login = async (auth: AuthData, type: "mobile" | "desktop") => {
  if (!auth.user) {
    await signInWithOAuth(ROUTES.account.home)
    sendTrackingEvent.authentication({
      data: { authenticationType: "sign_in" },
      gaEvent: {
        action: "authentication_type",
        label: `sign_in-${type}`,
      },
    })
  } else {
    router.push(ROUTES.account.home)
  }
}
