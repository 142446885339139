import { FC, KeyboardEvent, PropsWithChildren } from "react"

import { Icon } from "src/components/common/icon"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"

import { Media } from "src/helpers/media"

import styles from "./Navigation.module.scss"

const bem = create(styles, "Navigation")

export type NavigationHeaderProps = PropsWithChildren<{
  variant: "default" | "accordion" | "icons"
  isExpanded?: boolean
  onToggle?: () => void
}>

export const NavigationHeader: FC<NavigationHeaderProps> = ({
  variant,
  isExpanded,
  onToggle,
  children,
}) => {
  const isAccordion = variant === "accordion"

  if (!children) {
    return null
  }

  const title = <h3 className={bem("title")}>{children}</h3>

  if (!isAccordion) {
    return title
  }

  const modifiers = {
    [variant]: true,
    expanded: !!isExpanded,
  }

  function onButtonKeyDown(e: KeyboardEvent<HTMLButtonElement>) {
    if (e.key === "Enter") {
      onToggle?.()
    }
  }

  return (
    <>
      <Media maxWidth={screenBreakpoints.sm}>
        <button
          className={bem("title-wrapper", modifiers)}
          onClick={onToggle}
          onKeyDown={onButtonKeyDown}
        >
          {title}

          {isAccordion && (
            <Icon
              name="dropdownMenuDown"
              className={bem("chevron-icon", modifiers)}
            />
          )}
        </button>
      </Media>

      <Media minWidth={screenBreakpoints.sm}>{title}</Media>
    </>
  )
}
