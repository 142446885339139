import { FC } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"
import { ContentfulButtonProps } from "src/components/cck/elements/contentfulButton"

import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"

import styles from "./NavigationDesktopBottom.module.scss"

const bem = create(styles, "NavigationDesktopBottom")

type NavigationDesktopBottomProps = {
  mainNavigationItem?: ContentfulButtonProps
  onNavigationContentLeave: () => void
}

export const NavigationDesktopBottom: FC<NavigationDesktopBottomProps> = ({
  mainNavigationItem,
  onNavigationContentLeave,
}) => {
  const { trackNavigationMainItemClick } = useNavigationTrackingEvents()

  const handleMainNavigationLinkClick = (value?: string) => {
    onNavigationContentLeave()
    trackNavigationMainItemClick(value, "desktop")
  }

  return (
    <>
      <div className={bem("swoosh")} />
      {!!mainNavigationItem && (
        <div className={bem("link")}>
          <Button
            variant={mainNavigationItem.variant ?? "secondary"}
            className={bem("button")}
            href={mainNavigationItem.link || ""}
            onClick={() =>
              handleMainNavigationLinkClick(
                mainNavigationItem.children as string,
              )
            }
          >
            {mainNavigationItem.children}
          </Button>
        </div>
      )}
    </>
  )
}
