import { FC } from "react"

import { useFooterTrackingEvents } from "../../sections/footer/hooks/useFooterTrackingEvents"
import { BaseLinkOrDiv } from "src/components/common/base-link/BaseLinkOrDiv"
import { Icon, IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import styles from "./NavigationItem.module.scss"

const bem = create(styles, "NavigationItem")

export type NavigationItemProps = {
  name?: string
  link?: string
  icon?: IconName | null
}

export const NavigationItem: FC<NavigationItemProps> = ({
  name,
  link,
  icon,
}) => {
  const { trackFooterNavigation } = useFooterTrackingEvents()

  const modifiers = {
    "with-icon": !!icon,
  }

  return (
    <BaseLinkOrDiv
      onClick={() => trackFooterNavigation(name)}
      className={bem(undefined, modifiers)}
      href={link}
    >
      {icon ? <Icon name={icon} className={bem("icon")} /> : name}
    </BaseLinkOrDiv>
  )
}
