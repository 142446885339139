import { FC, useState } from "react"

import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"
import { signOut } from "src/helpers/next-auth/client"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useTranslation } from "src/hooks/translation"
import accountActions from "src/states/account/accountActions"
import { setServiceFlowData, useServiceFlowData } from "src/states/serviceFlow"

import styles from "./SignOut.module.scss"

const bem = create(styles, "SignOut")

export const SignOut: FC = () => {
  const { messages } = useTranslation()
  const translations = messages.pages.account.index

  useServiceFlowData()

  const [disabled, setDisabled] = useState(false)

  const handleSingOutClick = () => {
    sendTrackingEvent.account({
      gaEvent: { action: "sign_out" },
    })
    setDisabled(true)
    signOut()
    accountActions.clearAccountData()
    setServiceFlowData({})
  }

  return (
    <Button
      size="lg"
      variant="secondary"
      className={bem("button")}
      disabled={disabled}
      onClick={handleSingOutClick}
      aria-label={translations.buttons.signOut}
      data-cy="signout-button"
    >
      {translations.buttons.signOut}
    </Button>
  )
}
