import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { StorageOptions } from "./syncToPersistentStorage"

export type TopBannerStatus = {
  isTopBannerClosed?: boolean
}

const sessionStorage: StorageOptions = {
  key: "top-banner-status",
  version: "2024-02-13",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<TopBannerStatus>({}, sessionStorage)

export {
  getValue as getTopBannerStatus,
  setValue as setTopBannerStatus,
  useValue as useTopBannerStatus,
  mergeValue as mergeTopBannerStatus,
}
