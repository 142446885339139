export const getNameInitials = (
  firstName?: string,
  lastName?: string,
  email?: string,
): string => {
  if (firstName && lastName)
    return `${firstName?.charAt(0)}${lastName?.charAt(0)}`

  if (firstName && !lastName) return firstName.substring(0, 2).toUpperCase()

  if (!firstName && lastName) return lastName.substring(0, 2).toUpperCase()

  if (email) return email?.substring(0, 2).toUpperCase()

  return ""
}
