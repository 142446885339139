import { FC } from "react"

import { BaseLink } from "src/components/common/base-link"
import { ROUTES } from "src/config"
import { create } from "src/helpers/bem"
import { useIsInitialized } from "src/helpers/usercentrics/hooks/useIsInitialized"
import { showSecondLayer } from "src/helpers/usercentrics/utils"
import { useTranslation } from "src/hooks/translation"

import styles from "./Footer.module.scss"

const bem = create(styles, "Footer")

export const Footer: FC = () => {
  const { messages } = useTranslation()
  const { footer } = messages.components.core

  const isUsercentricsInitialized = useIsInitialized()

  const handleUserCentricsClick = () => {
    if (isUsercentricsInitialized) {
      showSecondLayer()
    }
  }

  return (
    <div className={bem()} role="contentinfo">
      <div className={bem("contents")}>
        <p className={bem("title")}>{footer.title}</p>

        <ul className={bem("list")}>
          <li className={bem("item")}>
            <BaseLink href={ROUTES.contact} className={bem("link")}>
              {footer.links.contact}
            </BaseLink>
          </li>
          <li className={bem("item")}>
            <BaseLink href={ROUTES.privacy} className={bem("link")}>
              {footer.links.privacy}
            </BaseLink>
          </li>
          <li className={bem("item")}>
            <BaseLink href={ROUTES.terms} className={bem("link")}>
              {footer.links.terms}
            </BaseLink>
          </li>
          <li className={bem("item")}>
            <BaseLink href={ROUTES.imprint} className={bem("link")}>
              {footer.links.imprint}
            </BaseLink>
          </li>
          <li className={bem("item")}>
            <BaseLink href={ROUTES.aboutUs} className={bem("link")}>
              {footer.links.about}
            </BaseLink>
          </li>
          <li className={bem("item")}>
            <button
              className={bem("link")}
              disabled={!isUsercentricsInitialized}
              onClick={handleUserCentricsClick}
            >
              {footer.links.userCentrics}
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}
