import { FC } from "react"

import { create } from "src/helpers/bem"
import { NavigationDesktopItem } from "src/hooks/navigation/useDesktopNavigationItems"
import { useTopBannerStatus } from "src/states/topBannerStatus"

import { NavigationDesktopBottom } from "./NavigationDesktopBottom"
import { NavigationDesktopItemContent } from "./NavigationDesktopItemContent"
import styles from "./NavigationDesktopItems.module.scss"

const bem = create(styles, "NavigationDesktopItems")

type NavigationDesktopItemsProps = {
  isNavigationPopoverOpen: boolean
  onNavigationContentHover: () => void
  onNavigationContentLeave: () => void
  item?: NavigationDesktopItem
}
export const NavigationDesktopItems: FC<NavigationDesktopItemsProps> = ({
  isNavigationPopoverOpen = false,
  item,
  onNavigationContentHover,
  onNavigationContentLeave,
}) => {
  const { isTopBannerClosed } = useTopBannerStatus()

  if (!item) {
    return
  }

  return (
    isNavigationPopoverOpen && (
      <>
        <div
          className={bem()}
          onMouseOver={onNavigationContentHover}
          onMouseLeave={onNavigationContentLeave}
        >
          <div className={bem("flyout-container")}>
            <NavigationDesktopItemContent
              item={item}
              onNavigationContentLeave={onNavigationContentLeave}
            />
            <NavigationDesktopBottom
              mainNavigationItem={item?.mainNavigationItem}
              onNavigationContentLeave={onNavigationContentLeave}
            />
          </div>
        </div>
        <div
          className={bem("overlay", {
            "no-top-banner": !!isTopBannerClosed,
          })}
        />
      </>
    )
  )
}
