import debounce from "lodash/debounce"
import { useRouter } from "next/router"
import { FC, useRef, useState } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"

import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"
import { NavigationDesktopItem } from "src/hooks/navigation/useDesktopNavigationItems"

import styles from "./NavigationDesktop.module.scss"
import { NavigationDesktopItems } from "./NavigationDesktopItems"

const bem = create(styles, "NavigationDesktop")

export type NavigationDesktopProps = {
  items: NavigationDesktopItem[]
}

export const NavigationDesktop: FC<NavigationDesktopProps> = ({ items }) => {
  const router = useRouter()

  const [isNavigationPopoverOpen, setIsNavigationPopoverOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<NavigationDesktopItem>()

  const { trackNavigationHover, trackNavigationMenuItemClick } =
    useNavigationTrackingEvents()
  //Only call with a delay to avoid unnecessary tracking while hovering the item
  const debouncedHandleNavigationHoverRef = useRef(
    debounce((item?: NavigationDesktopItem) => {
      setSelectedItem(item)
      setIsNavigationPopoverOpen(true)
      trackNavigationHover(item?.text, "desktop")
    }, 500),
  )

  const debouncedHandleNavigationLeaveRef = useRef(
    debounce(() => {
      setIsNavigationPopoverOpen(false)
    }, 500),
  )

  const handleNavigationHover = (item?: NavigationDesktopItem) => {
    debouncedHandleNavigationLeaveRef.current.cancel()
    debouncedHandleNavigationHoverRef.current(item)
  }

  const handleMouseLeave = () => {
    debouncedHandleNavigationLeaveRef.current()
    debouncedHandleNavigationHoverRef.current.cancel()
  }

  const onNavigationContentHover = () => {
    debouncedHandleNavigationLeaveRef.current.cancel()
    setIsNavigationPopoverOpen(true)
  }

  const onNavigationContentLeave = () => {
    debouncedHandleNavigationLeaveRef.current()
  }

  return !!items.length ? (
    <nav aria-label="Navigation" className={bem()}>
      <ul className={bem("nav")}>
        {items.map((item) => (
          <li
            key={item?.text}
            onMouseOver={() => handleNavigationHover(item)}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              className={bem("item", {
                active: router.asPath === item?.mainNavigationItem?.link,
              })}
              variant="tertiary"
              href={item?.mainNavigationItem?.link || ""}
              onClick={() => trackNavigationMenuItemClick(item?.text)}
            >
              {item?.text}
            </Button>
          </li>
        ))}
      </ul>
      <NavigationDesktopItems
        onNavigationContentHover={onNavigationContentHover}
        onNavigationContentLeave={onNavigationContentLeave}
        isNavigationPopoverOpen={isNavigationPopoverOpen}
        item={selectedItem}
      />
    </nav>
  ) : null
}
