import { ContentfulButtonProps } from "src/components/cck/elements/contentfulButton"
import { NavigationItemProps } from "src/components/cck/elements/navigationItem"
import { IconName } from "src/components/common/icon"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

export type NavigationDesktopItemContent = {
  text?: string
  link?: string
  icon?: IconName | null
  content?: {
    text?: string
    href?: string
    icon?: IconName | null
  }[]
}

export type NavigationDesktopItem = {
  id?: string
  text?: string
  href?: string
  mainNavigationItem?: ContentfulButtonProps
  content?: NavigationDesktopItemContent[]
}

export const useDesktopNavigationItems = (
  headerNavigation?: HeaderNavigationProps[] | null,
) => {
  const getSubNavigationItems = (
    title?: string,
    subNavigationItems?: NavigationItemProps[],
  ) => {
    // Only show maximum 3 sub items for Ratgeber menu
    if (title === "Ratgeber") {
      subNavigationItems = subNavigationItems?.slice(0, 3)
    }
    return subNavigationItems?.map((item) => {
      return {
        text: item?.name,
        href: item?.link,
        icon: item?.icon,
      }
    })
  }
  const headerNavigationItems: NavigationDesktopItemContent[] =
    !!headerNavigation?.length
      ? headerNavigation?.map((navigationItem) => {
          return {
            text: navigationItem.title ?? "",
            mainNavigationItem: navigationItem.mainNavigationItem,
            content: navigationItem?.navigationItems?.map(
              (navigationSubItem) => {
                return {
                  text: navigationSubItem?.name,
                  link: navigationSubItem?.link,
                  icon: navigationSubItem?.icon,
                  content: getSubNavigationItems(
                    navigationItem.title,
                    navigationSubItem.navigationItems,
                  ),
                }
              },
            ),
          }
        })
      : []
  return headerNavigationItems
}
