import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { StorageOptions } from "./syncToPersistentStorage"

export type LoginPopoverStatus = {
  interacted?: boolean
}

const sessionStorage: StorageOptions = {
  key: "login-popover-status",
  version: "2024-06-26",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<LoginPopoverStatus>({}, sessionStorage)

export {
  getValue as getLoginPopoverStatus,
  setValue as setLoginPopoverStatus,
  useValue as useLoginPopoverStatus,
  mergeValue as mergeLoginPopoverStatus,
}
