import { FC, useEffect, useState } from "react"

import { useMediaQuery } from "react-responsive"

import { ReactComponent as UserLogo } from "src/assets/account/user.svg"
import { Popover } from "src/components/common/popover"
import { LoginPopoverContent } from "src/components/core/accountIcon/loginPopover"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { getNameInitials } from "src/helpers/getNameInitials"
import { useTranslation } from "src/hooks/translation"

import { useABTestVariationValue } from "src/hooks/useABTestVariationValue"
import { useFlow } from "src/hooks/useFlow"

import { useLoginPopoverStatus } from "src/states/loginPopoverStatus"

import styles from "./AccountIcon.module.scss"

const bem = create(styles, "AccountIcon")

export type Account = {
  firstName?: string
  lastName?: string
  email?: string
}

export type AccountIconProps = {
  account?: Account
  onClick?: () => void
}

export const AccountIcon: FC<AccountIconProps> = ({ account, onClick }) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.accountIcon
  const { isIframeBookingFlow } = useFlow()

  const [popoverOpen, setPopoverOpen] = useState(false)
  const { interacted: interactedWithPopover } = useLoginPopoverStatus()
  const isRelocateLoginTestVariant1 = useABTestVariationValue(
    "isRelocateLoginTestVariant1",
  )

  const isMobile = useMediaQuery({
    maxWidth: screenBreakpoints.sm,
  })

  const isAuthenticated = !!account
  const nameInitials = getNameInitials(
    account?.firstName,
    account?.lastName,
    account?.email,
  )

  const showLoginPopover =
    !isAuthenticated && !interactedWithPopover && isRelocateLoginTestVariant1

  useEffect(() => {
    if (showLoginPopover) {
      // Automatically open the login popover after 2 seconds
      const timer = setTimeout(() => {
        setPopoverOpen(true)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [showLoginPopover])

  const Content = () => {
    if (isAuthenticated) {
      return <span className={bem("initials")}>{nameInitials}</span>
    }

    return (
      <>
        <UserLogo className={bem("icon")} aria-label={translations.title} />
        <span className={bem("label")}>{translations.title}</span>
      </>
    )
  }

  if (isIframeBookingFlow) {
    return (
      <div className={bem(undefined, { isAuthenticated })}>
        <Content />
      </div>
    )
  }

  const accountIcon = (
    <button
      onClick={onClick}
      className={bem(undefined, {
        isAuthenticated,
        isIframeBookingFlow,
      })}
    >
      <Content />
    </button>
  )

  if (!showLoginPopover) {
    // return account icon without popover
    return accountIcon
  }

  return (
    <>
      <Popover
        trigger={accountIcon}
        open={popoverOpen}
        avoidCollisions
        collisionPadding={12}
        sideOffset={isMobile ? 8 : 16}
        alignOffset={-24}
        align="end"
      >
        <LoginPopoverContent onClose={() => setPopoverOpen(false)} />
      </Popover>
    </>
  )
}
