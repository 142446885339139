import { FC } from "react"

import { login } from "../helpers/login"
import { useKeycloakAuthError } from "../hooks/keycloakAuthError"
import { AccountIcon } from "src/components/core/accountIcon"
import { Logo } from "src/components/core/logo"
import { NavigationMobile } from "src/components/core/navigation/navigationMobile"
import { ShoppingCart } from "src/components/core/shoppingCart"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { Media } from "src/helpers/media"
import { useAuth } from "src/helpers/next-auth/client"

import { useFlow } from "src/hooks/useFlow"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

import styles from "./HeaderMobile.module.scss"

const bem = create(styles, "HeaderMobile")

export type HeaderMobileProps = {
  headerNavigation?: HeaderNavigationProps[] | null
  showNavigation?: boolean
  showShoppingCart?: boolean
}

export const HeaderMobile: FC<HeaderMobileProps> = ({
  headerNavigation,
  showNavigation,
  showShoppingCart,
}) => {
  const auth = useAuth({ required: false })
  const { isIframeBookingFlow } = useFlow()

  useKeycloakAuthError(auth)

  const onLoginClick = async () => {
    login(auth, "mobile")
  }

  const showAccountIcon = !isIframeBookingFlow

  return (
    <Media maxWidth={screenBreakpoints.lg}>
      <div className={bem()}>
        <div className={bem("row")} role="banner">
          {showNavigation && (
            <NavigationMobile
              headerNavigation={headerNavigation}
              account={auth.user}
              onLoginClick={onLoginClick}
            />
          )}
          <div className={bem("logo")}>
            <Logo />
          </div>

          <div className={bem("nav")}>
            {showAccountIcon && (
              <AccountIcon account={auth.user} onClick={onLoginClick} />
            )}

            {showShoppingCart && <ShoppingCart />}
          </div>
        </div>
      </div>
    </Media>
  )
}
