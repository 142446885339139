import { FC } from "react"

import { NavigationProps } from "../navigation"
import { ContentfulRichText } from "src/components/contentful/components/ContentfulRichText"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import { ContentfulRichTextData } from "src/types/contentful/autoservice"

import styles from "./Footer.module.scss"
import { FooterItem } from "./FooterItem"

const bem = create(styles, "Footer")

export type FooterProps = {
  supportingText: ContentfulRichTextData | null
  topNavigation: NavigationProps[] | null
  bottomNavigation: NavigationProps[] | null
}

export const Footer: FC<FooterProps> = ({
  topNavigation,
  bottomNavigation,
  supportingText,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.cck.footer

  if (!topNavigation && !bottomNavigation) {
    return null
  }

  return (
    <footer className={bem()}>
      {supportingText && (
        <div className={bem("supporting-text-wrapper")}>
          <ContentfulRichText
            content={supportingText}
            className={bem("supporting-text")}
          />
        </div>
      )}

      <FooterItem
        variant="top"
        navigationSections={topNavigation}
        isLastGroup={getShouldLastSectionsGroup(topNavigation)}
      />

      <FooterItem variant="bottom" navigationSections={bottomNavigation}>
        <span key="copyright" className={bem("copyright")}>
          {translations.copyright}
        </span>
      </FooterItem>
    </footer>
  )
}

function getShouldLastSectionsGroup(navigation?: NavigationProps[] | null) {
  return (
    navigation?.[navigation.length - 1]?.variant === "icons" &&
    navigation?.[navigation.length - 2]?.variant === "icons"
  )
}
