import { useRouter } from "next/router"
import { FC, Fragment, useState } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"
import { BaseLink } from "src/components/common/base-link"
import { Icon } from "src/components/common/icon"

import { create } from "src/helpers/bem"
import {
  NavigationMobileItem,
  NavigationMobileItemContent as NavigationMobileItemContentType,
} from "src/hooks/navigation/useMobileNavigationItems"
import { useTranslation } from "src/hooks/translation"

import styles from "./NavigationMobileItemContent.module.scss"

const bem = create(styles, "NavigationMobileItemContent")

export type NavigationMobileItemContentProps = {
  mainNavigationSelected: boolean
  selectedMainNavigationItem: NavigationMobileItem
  items: NavigationMobileItemContentType[]
  onBackButtonClick: () => void
}

export const NavigationMobileItemContent: FC<
  NavigationMobileItemContentProps
> = ({
  mainNavigationSelected,
  selectedMainNavigationItem,
  items,
  onBackButtonClick,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.navigation.mobile
  const router = useRouter()

  const {
    trackNavigationSubItemClick,
    trackNavigationLinkClick,
    trackNavigationBackButtonClick,
  } = useNavigationTrackingEvents()

  const [selectedItem, setSelectedItem] =
    useState<NavigationMobileItemContentType | null>(
      () =>
        items?.find((item) =>
          item.content?.find((item) => item.href === router.asPath),
        ) || null,
    )

  const [hideMainNavigation, setHideMainNavigation] = useState(
    !mainNavigationSelected && !!selectedItem,
  )

  if (!items?.length) {
    return null
  }

  const handleSelectedItemClick = (item: NavigationMobileItemContentType) => {
    trackNavigationSubItemClick(item.text, "mobile")
    setSelectedItem(item)
    setHideMainNavigation(true)
  }

  const trackLinkClick = (title?: string) => {
    trackNavigationLinkClick(title, "mobile")
  }

  const onBackNavigationClick = () => {
    trackNavigationBackButtonClick("mobile")
    if (hideMainNavigation) {
      setHideMainNavigation(false)
    } else {
      onBackButtonClick()
    }
  }

  const { text, mainNavigationItem } = selectedMainNavigationItem

  const renderTitle = (title?: string) => {
    //Only to modify the title for Ratgeber menu
    if (text === "Ratgeber") {
      return `${title}-${text}`
    }

    return title
  }

  const renderLinks = (items: NavigationMobileItemContentType["items"]) => {
    return items?.map((subItem, subIndex) => (
      <li key={subIndex}>
        <BaseLink
          className={bem("item", {
            current: subItem.href === router.asPath,
          })}
          href={subItem.href || ""}
          onClick={() => trackLinkClick(subItem.text)}
          shallow={false}
        >
          {subItem.text}
        </BaseLink>
      </li>
    ))
  }

  return (
    <>
      <button className={bem("back-button")} onClick={onBackNavigationClick}>
        <Icon
          className={bem("back-button__icon")}
          name="dropdownMenuDown"
          aria-hidden="true"
        />
        {translations.buttons.back}
      </button>

      {!hideMainNavigation && (
        <>
          <p className={bem("title")}>{text}</p>
          {!!mainNavigationItem && (
            <BaseLink
              className={bem("item")}
              href={mainNavigationItem.link}
              onClick={() =>
                trackLinkClick(mainNavigationItem.children as string)
              }
              shallow={false}
            >
              {mainNavigationItem.children}
            </BaseLink>
          )}
          {items?.map((item, index) => (
            <Fragment key={index}>
              {!!item.content?.length ? (
                <button
                  className={bem("item", "sub-item")}
                  onClick={() => handleSelectedItemClick(item)}
                >
                  {item.text}
                  <Icon
                    className={bem("item__icon")}
                    name="dropdownMenuDown"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <ul>
                  {item.text && <li className={bem("title")}>{item.text}</li>}
                  {renderLinks(item.items)}
                </ul>
              )}
            </Fragment>
          ))}
        </>
      )}

      {hideMainNavigation && !!selectedItem && (
        <ul>
          <li className={bem("title")}>{renderTitle(selectedItem.text)}</li>
          {renderLinks(selectedItem.content)}
        </ul>
      )}
    </>
  )
}
