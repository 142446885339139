import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

type NavigationLabel = "mobile" | "desktop"

export const useNavigationTrackingEvents = () => {
  function trackNavigationMenuItemClick(label?: string) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_menu_item_click",
        label,
      },
    })
  }

  function trackNavigationMainItemClick(
    value?: string,
    label?: NavigationLabel,
  ) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_main_item_click",
        label,
        value,
      },
    })
  }

  function trackNavigationSubItemClick(
    value?: string,
    label?: NavigationLabel,
  ) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_sub_item_click",
        label,
        value,
      },
    })
  }

  function trackNavigationLinkClick(value?: string, label?: NavigationLabel) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_link_click",
        label,
        value,
      },
    })
  }

  function trackNavigationBackButtonClick(label: NavigationLabel) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_back_button_click",
        label,
      },
    })
  }

  function trackNavigationHover(value?: string, label?: NavigationLabel) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: "navigation_hover",
        label,
        value,
      },
    })
  }

  function trackNavigationHamburgerMenu(open: boolean) {
    sendTrackingEvent.navigationBar({
      gaEvent: {
        action: open
          ? "navigation_hamburger_menu_open"
          : "navigation_hamburger_menu_close",
      },
    })
  }

  return {
    trackNavigationMenuItemClick,
    trackNavigationMainItemClick,
    trackNavigationSubItemClick,
    trackNavigationLinkClick,
    trackNavigationBackButtonClick,
    trackNavigationHover,
    trackNavigationHamburgerMenu,
  }
}
