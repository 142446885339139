import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useFooterTrackingEvents = () => {
  function trackFooterNavigation(name?: string) {
    sendTrackingEvent.footer({
      gaEvent: {
        action: "footer_navigation_click",
        label: name,
      },
    })
  }

  function trackAccordionInteraction(value: boolean, name?: string) {
    const action = value ? "footer_accordion_open" : "footer_accordion_close"
    sendTrackingEvent.footer({
      gaEvent: {
        action,
        label: name,
      },
    })
  }

  return {
    trackFooterNavigation,
    trackAccordionInteraction,
  }
}
