import { FC } from "react"

import { AccountIcon } from "../../accountIcon"
import { login } from "../helpers/login"
import { useKeycloakAuthError } from "../hooks/keycloakAuthError"
import { Logo } from "src/components/core/logo"
import { NavigationDesktop } from "src/components/core/navigation/navigationDesktop"
import { ShoppingCart } from "src/components/core/shoppingCart"

import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { Media } from "src/helpers/media"
import { useAuth } from "src/helpers/next-auth/client"
import { useDesktopNavigationItems } from "src/hooks/navigation/useDesktopNavigationItems"

import { useFlow } from "src/hooks/useFlow"

import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

import styles from "./HeaderDesktop.module.scss"

const bem = create(styles, "HeaderDesktop")

export type HeaderDesktopProps = {
  headerNavigation?: HeaderNavigationProps[] | null
  showbookServiceLink?: boolean
  showShoppingCart?: boolean
}

export const HeaderDesktop: FC<HeaderDesktopProps> = ({
  headerNavigation,
  showShoppingCart,
}) => {
  const { isIframeBookingFlow } = useFlow()

  const navigationItems = useDesktopNavigationItems(headerNavigation)

  const auth = useAuth({ required: false })

  useKeycloakAuthError(auth)

  const onLoginClick = async () => {
    login(auth, "desktop")
  }

  const showAccountIcon = !isIframeBookingFlow

  return (
    <Media minWidth={screenBreakpoints.lg}>
      <div className={bem()}>
        <div className={bem("row", { top: true })} role="banner">
          <div className={bem("row__container")} role="banner">
            <Logo />

            {!!navigationItems?.length && (
              <NavigationDesktop items={navigationItems} />
            )}

            <div className={bem("links")}>
              {showAccountIcon && (
                <AccountIcon account={auth.user} onClick={onLoginClick} />
              )}

              {showShoppingCart && <ShoppingCart />}
            </div>
          </div>
        </div>
      </div>
    </Media>
  )
}
