import { FC, PropsWithChildren } from "react"

import { Navigation, NavigationProps } from "../navigation"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"

import { Media } from "src/helpers/media"

import styles from "./FooterItem.module.scss"

const bem = create(styles, "FooterItem")

type FooterItemProps = PropsWithChildren<{
  variant: "top" | "bottom"
  navigationSections?: NavigationProps[] | null
  isLastGroup?: boolean
}>

export const FooterItem: FC<FooterItemProps> = ({
  variant,
  navigationSections,
  isLastGroup,
  children,
}) => {
  if (!navigationSections?.length) {
    return null
  }

  const navigation = [...navigationSections]
  const groupLastElement = isLastGroup && navigation.pop()

  return (
    <div className={bem("container")}>
      <div className={bem(variant)}>
        {navigation.map((navigationSection, index) => (
          <Navigation
            {...navigationSection}
            key={`${navigationSection.name ?? index}-${index}`}
          >
            {children}

            {groupLastElement && index === navigation.length - 1 && (
              <Media
                minWidth={screenBreakpoints.md}
                key={groupLastElement.name}
              >
                <Navigation {...groupLastElement} />
              </Media>
            )}
          </Navigation>
        ))}

        {groupLastElement && (
          <Media maxWidth={screenBreakpoints.md} key={groupLastElement.name}>
            <Navigation {...groupLastElement} />
          </Media>
        )}
      </div>
    </div>
  )
}
