import { useRouter } from "next/router"
import { FC, useState } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"
import { Icon } from "src/components/common/icon"

import { create } from "src/helpers/bem"

import { NavigationMobileItem } from "src/hooks/navigation/useMobileNavigationItems"

import { NavigationMobileItemContent } from "./NavigationMobileItemContent"

import styles from "./NavigationMobileItems.module.scss"

const bem = create(styles, "NavigationMobileItems")

export type NavigationMobileItemsProps = {
  items: NavigationMobileItem[] | undefined
}

export const NavigationMobileItems: FC<NavigationMobileItemsProps> = ({
  items,
}) => {
  const router = useRouter()
  const [selectedItem, setSelectedItem] = useState<NavigationMobileItem | null>(
    () =>
      items?.find(
        (item) =>
          !!item.activeHrefs?.some((href) => router.asPath.includes(href)),
      ) || null,
  )

  const { trackNavigationMainItemClick } = useNavigationTrackingEvents()

  const [mainNavigationSelected, setMainNavigationSelected] = useState(false)

  if (!items?.length) {
    return null
  }

  const onBackButtonClick = () => setSelectedItem(null)

  const handleMobileNavigationItemClick = (item: NavigationMobileItem) => {
    trackNavigationMainItemClick(item.text, "mobile")

    if (item.content) {
      setSelectedItem(item)
      setMainNavigationSelected(true)
    } else {
      router.push(item.href || "", undefined, { shallow: false })
    }
  }

  return (
    <nav className={bem()} aria-label="Navigation">
      {selectedItem?.content?.length ? (
        <NavigationMobileItemContent
          mainNavigationSelected={mainNavigationSelected}
          selectedMainNavigationItem={selectedItem}
          items={selectedItem.content}
          onBackButtonClick={onBackButtonClick}
        />
      ) : (
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <button
                id={item.id}
                className={bem("item", { text: !item.content && !item.href })}
                onClick={() => handleMobileNavigationItemClick(item)}
              >
                {item.text}
                {!!item.content && (
                  <Icon
                    className={bem("item__icon")}
                    name="dropdownMenuDown"
                    aria-hidden="true"
                  />
                )}
              </button>
            </li>
          ))}
        </ul>
      )}
    </nav>
  )
}
