import { useRouter } from "next/router"
import { FC, useEffect, useState } from "react"

import { useNavigationTrackingEvents } from "../hooks/useNavigationTrackingEvents"
import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"

import { Modal, ModalContent, ModalTrigger } from "src/components/common/modal"
import { AccountIcon } from "src/components/core/accountIcon"
import { SignOut } from "src/components/layouts/account/SignOut"
import { ROUTES } from "src/config"

import { create } from "src/helpers/bem"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useMobileNavigationItems } from "src/hooks/navigation/useMobileNavigationItems"
import { useTranslation } from "src/hooks/translation"

import { useFlow } from "src/hooks/useFlow"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

import styles from "./NavigationMobile.module.scss"
import { NavigationMobileItems } from "./NavigationMobileItems"

const bem = create(styles, "NavigationMobile")

export type Account = {
  firstName?: string
  lastName?: string
}

export type NavigationMobileProps = {
  headerNavigation?: HeaderNavigationProps[] | null
  account: Account | undefined
  onLoginClick: () => void
}

export const NavigationMobile: FC<NavigationMobileProps> = ({
  headerNavigation,
  account,
  onLoginClick,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.navigation.mobile

  const isAuthenticated = !!account
  const accountFullName =
    isAuthenticated && `${account.firstName} ${account.lastName}`

  const navigationItems = useMobileNavigationItems(
    isAuthenticated,
    headerNavigation,
  )
  const { isIframeBookingFlow } = useFlow()
  const router = useRouter()

  const [open, setOpen] = useState(false)

  const { trackNavigationHamburgerMenu } = useNavigationTrackingEvents()

  useEffect(() => {
    setOpen(false)
  }, [router.asPath])

  function onOpenChange(open: boolean) {
    setOpen(open)
    trackNavigationHamburgerMenu(open)
  }

  const bookServiceButton = (
    <Button
      variant="primary"
      href={ROUTES.serviceFlow({ flowStep: "index" })}
      data-cy="book-service-button"
      onClick={() => {
        sendTrackingEvent.navigationBar({
          gaEvent: {
            action: "cta_go_to_service_selection",
            label: "navigation_mobile",
          },
        })
      }}
    >
      {translations.buttons.bookService}
    </Button>
  )

  const footerButtons = () => {
    if (isIframeBookingFlow) {
      return <div className={bem("buttons")}>{bookServiceButton}</div>
    }

    return (
      <div className={bem("buttons")}>
        {bookServiceButton}
        {isAuthenticated ? (
          <SignOut />
        ) : (
          <Button
            variant="secondary"
            onClick={onLoginClick}
            data-cy="login-register"
          >
            {translations.buttons.login}
          </Button>
        )}
      </div>
    )
  }

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <ModalTrigger>
        <button className={bem("menu")} data-cy="navigation">
          <Icon name="hamburgerMenu" aria-hidden="true" />
        </button>
      </ModalTrigger>

      {open && (
        <ModalContent
          variant="lg"
          title={translations.modalTitle}
          noPadding
          footer={footerButtons()}
        >
          <div className={bem("content")}>
            {isAuthenticated && (
              <div className={bem("account")}>
                <AccountIcon account={account} />
                <div>{accountFullName}</div>
              </div>
            )}

            <NavigationMobileItems items={navigationItems} />
          </div>
        </ModalContent>
      )}
    </Modal>
  )
}
