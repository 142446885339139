import { FC, PropsWithChildren, useState } from "react"

import {
  NavigationItem,
  NavigationItemProps,
} from "../../elements/navigationItem"
import { useFooterTrackingEvents } from "../footer/hooks/useFooterTrackingEvents"
import { IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import styles from "./Navigation.module.scss"
import { NavigationHeader } from "./NavigationHeader"

const bem = create(styles, "Navigation")

export type NavigationProps = PropsWithChildren<{
  name?: string
  variant: "default" | "accordion" | "icons"
  link?: string
  icon?: IconName | null
  navigationItems: NavigationItemProps[]
}>

export const Navigation: FC<NavigationProps> = ({
  name,
  variant,
  navigationItems,
  children,
}) => {
  const isAccordion = variant === "accordion"
  const [isExpanded, setIsExpanded] = useState(false)
  const { trackAccordionInteraction } = useFooterTrackingEvents()

  const modifiers = {
    [variant]: true,
    expanded: isAccordion ? isExpanded : true,
  }

  function onIsExpandedToggle() {
    setIsExpanded(!isExpanded)
    trackAccordionInteraction(!isExpanded, name)
  }

  return (
    <section className={bem(undefined, modifiers)}>
      <NavigationHeader
        variant={variant}
        isExpanded={isExpanded}
        onToggle={onIsExpandedToggle}
      >
        {name}
      </NavigationHeader>

      <ul className={bem("list", modifiers)}>
        {navigationItems.map((item, index) => (
          <li
            id={`${item.name ?? index}-${index}`}
            key={`${item.name ?? index}-${index}`}
            className={bem("item", { ...modifiers, "full-width": !item.icon })}
          >
            <NavigationItem {...item} />
          </li>
        ))}

        {children}
      </ul>
    </section>
  )
}
